@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '../../../../libs/ui/Style/general/typography.css';
@import '../../../../libs/ui/Style/general/body.css';
@import '../../../../libs/ui/Style/general/grid.css';
@import '../../../../libs/ui/fonts/fonts-main-site.css';
@import '../../../../libs/ui/Style/general/buttons.css';
@import '../../../../libs/ui/Style/general/exploreButton.css';

@layer base {
  :root {
    --radius: 0.5rem;
  }
}
body {
  font-family: Nonserif, sans-serif;
}
