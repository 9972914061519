body {
  @apply bg-background text-text_color;
  overflow-x: hidden;

}

.container:not(.header-container) {
  @apply pl-0 pr-0;
}

@layer utilities {
  .fullscreen {
    @apply min-h-screen flex items-center justify-center;
  }

  .margin-top {
    @apply mt-[200px] xl:mt-[150px] hd:mt-[200px] 2k:mt-[250px] !important;
  }

  .margin-bottom {
    @apply mb-[200px] xl:mb-[150px] hd:mb-[200px] 2k:mb-[250px] !important;
  }

  .margin-top-mobile {
    @apply mt-[200px] xl:mt-[0] hd:mt-[0] 2k:mt-[0] !important;
  }

  .margin-bottom-mobile {
    @apply mb-[200px] xl:mb-[0] hd:mb-[0] 2k:mb-[0] !important;
  }
}

b, strong {
  font-weight: bold !important;
}

main section:first-of-type.herosection,
main section:first-of-type.fullImageVideo {
  @apply mt-[-80px] mb-[80px] xl:mt-[-60px] xl:mb-[60px] hd:mt-[-80px] hd:mb-[80px] 2k:mt-[-100px] 2k:mb-[100px];
  min-height: calc(100vh + 80px);
  @media (min-width: 1280px) {
    min-height: calc(100vh + 60px);
  }
  @media (min-width: 1920px) {
    min-height: calc(100vh + 80px);
  }
  @media (min-width: 2560px) {
    min-height: calc(100vh + 100px);
  }
}

main section:first-of-type.herosection .hero-right-headline,
main section:first-of-type.herosection .hero-right-subline {
  @apply pb-[120px] md:pb-[180px] xl:pb-[160px] hd:pb-[180px] 2k:pb-[200px];
}


main section:first-of-type.herosection .hero-right-shape {
  @apply pt-[120px] md:pt-[180px] xl:pt-[160px] hd:pt-[180px] 2k:pt-[200px];
}


main section:not(:first-of-type).herosection .hero-right-headline,
main section:not(:first-of-type).herosection .hero-right-subline {
  @apply pb-[40px] md:pb-[100px];
}

main section:not(:first-of-type).herosection .hero-right-shape {
  @apply pt-[40px] md:pt-[100px];
}

main section.herosection .hero-headline-mobile {
  @apply mb-[40px];
}

main section.herosection .hero-button-mobile {
  @apply mt-[40px];
}

main section.herosection .hero-shapes-mobile {
  @apply pb-[40px];
}

/* Container definitions */
.\!container {
  width: 100% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 350px) {
  .\!container {
    max-width: 350px !important;
  }
  .container {
    max-width: 350px;
  }
}

@media (min-width: 428px) {
  .\!container {
    max-width: 388px !important;
  }
  .container {
    max-width: 388px;
  }
}

@media (min-width: 576px) {
  .\!container {
    max-width: 540px !important;
  }
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 720px !important;
  }
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .\!container {
    max-width: 960px !important;
  }
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1168px !important;
  }
  .container {
    max-width: 1168px;
  }
}

@media (min-width: 1920px) {
  .\!container {
    max-width: 1760px !important;
  }
  .container {
    max-width: 1760px;
  }
}

@media (min-width: 2560px) {
  .\!container {
    max-width: 2330px !important;
  }
  .container {
    max-width: 2330px;
  }
}
