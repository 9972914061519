.button {
  display: flex;
  @apply text-small-caption xl:text-medium-caption hd:text-large-caption 2k:text-xlarge-caption;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.primary-button {
  padding: 13px 18px 13px 18px;
  @screen xl {
    padding: 22px 25px 22px 25px;
  }
  @apply bg-text_color text-background;
  box-shadow: 0 0 0 2px currentColor;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: background-color 0.375s ease-in-out,
  color 0.375s ease-in-out,
  box-shadow 0.2s ease-in-out;

  &:hover {
    @apply bg-background text-text_color;
  }

  &:active {
    box-shadow: 0 0 0 3px currentColor;
  }

}

.secondary-button {
  padding: 13px 18px 13px 18px;
  @screen xl {
    padding: 22px 25px 22px 25px;
  }
  @apply bg-background text-text_color;
  box-shadow: 0 0 0 2px currentColor;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: background-color 0.375s ease-in-out,
  color 0.375s ease-in-out,
  box-shadow 0.2s ease-in-out,
  border-width 0.2s ease-in-out;


  &:hover {
    box-shadow: 0 0 0 1px currentColor;
  }

  &:active {
    box-shadow: 0 0 0 3px currentColor;
  }

}

.tertiary-button {
  border-radius: 30px;
  padding: 10px 12px 10px 12px;
  @screen xl {
    padding: 14px 20px 14px 20px;
  }
  justify-content: center;
  align-items: center;
  @apply bg-text_color text-background border-[2px] border-text_color;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: background-color 0.375s ease-in-out,
  color 0.375s ease-in-out,
  box-shadow 0.2s ease-in-out;

  &:hover {
    @apply bg-background text-text_color;
  }

  &:active {
    box-shadow: 0 0 0 3px currentColor;
  }
}


.text-button {
  cursor: pointer;
  @apply text-text_color;
  text-decoration: none;
  display: flex;

  &:hover {
    border-bottom: 1px solid currentColor;
  }
}
