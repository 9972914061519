@font-face {
  font-family: 'Headingfont';
  src: url('/fonts/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nonserif';
  src: url('/fonts/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Headingfont';
  src: url('/fonts/HelveticaNeueThin.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Nonserif';
  src: url('/fonts/HelveticaNeueThin.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Headingfont';
  src: url('/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Nonserif';
  src: url('/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Headingfont';
  src: url('/fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Nonserif';
  src: url('/fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Headingfont';
  src: url('/fonts/HelveticaNeueBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nonserif';
  src: url('/fonts/HelveticaNeueBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
