@layer utilities {
  .contentGrid {
    @apply grid grid-cols-4 gap-x-4 xl:gap-x-custom-xl-gap hd:gap-x-custom-hd-gap 2k:gap-x-custom-2k-gap w-full;

  }

  .gridContainer {
    position: relative;
  }
}
